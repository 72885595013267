import React from 'react';
import Footer from '../../../layout/Footer/Footer';

const DefaultFooter = () => {
	const visible = true;

	if (!visible) return <br />;

	return (
		<Footer>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col'>
						<span className='fw-light text-center'>
							{' '}
							This website is for informational purposes only and does not provide
							investment, financial, or professional advice. Users are solely
							responsible for their own decisions and actions. The website owner
							assumes no liability for any losses or damages resulting from the use of
							the information provided.
						</span>
					</div>
				</div>
			</div>
		</Footer>
	);
};

export default DefaultFooter;
