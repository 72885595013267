import React from 'react';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import Navigation from '../../../layout/Navigation/Navigation';
import { topPagesMenu } from '../../../menu';
import useDeviceScreen from '../../../hooks/useDeviceScreen';

const DefaultHeader = () => {
	const { width } = useDeviceScreen();
	const visible = true;

	if (!visible) return <br />;

	return (
		<Header>
			<HeaderLeft>
				<Navigation
					menu={{ ...topPagesMenu }}
					id='header-top-menu'
					horizontal={
						!!width && width >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
					}
				/>
			</HeaderLeft>
			<HeaderRight>
				<div className='row g-3 align-items-center'>
					<div className='col-auto'>
						<a
							href='https://buymeacoffee.com/xescuder'
							target='_blank'
							rel='noopener noreferrer'
							className='btn btn-primary'>
							Buy Me a Coffee
						</a>
					</div>
				</div>
			</HeaderRight>
		</Header>
	);
};

export default DefaultHeader;
