import React, { useContext } from 'react';
import Brand from '../../../layout/Brand/Brand';
import ThemeContext from '../../../contexts/themeContext';
import Aside, { AsideHead } from '../../../layout/Aside/Aside';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const visible = false;

	if (!visible) return <br />;

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
		</Aside>
	);
};

export default DefaultAside;
