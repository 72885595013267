export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
};

export const authPagesMenu = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
};

export const topPagesMenu = {
	dashboard: {
		id: 'market',
		text: 'Dashboard',
		path: 'markets',
		icon: 'Dashboard',
		subMenu: null,
	},
	markets: {
		id: 'marketOverview',
		text: 'Market Overview',
		path: 'page-layouts',
		icon: 'BackupTable',
		subMenu: {
			indexes: {
				id: 'indexes',
				text: 'Indexes',
				path: 'indexes',
				icon: 'Dashboard',
				subMenu: null,
			},
			sectors: {
				id: 'sectors',
				text: 'Sectors',
				path: 'sectors',
				icon: 'Dashboard',
				subMenu: null,
			},
		},
	},
	screeners: {
		id: 'predefinedScreeners',
		text: 'Predefined Screeners',
		path: 'screeners',
		subMenu: null,
	},
	screener: {
		id: 'screener',
		text: 'Screener',
		path: 'screener',
		icon: 'Dashboard',
		subMenu: null,
	},
};
