import React from 'react';
import { useMeasure } from 'react-use';
import PropTypes from 'prop-types';

const Footer = ({ children }) => {
	const [ref, { height }] = useMeasure();

	const root = document.documentElement;
	root.style.setProperty('--footer-height', `${height}px`);

	return (
		<footer ref={ref} className='footer'>
			{children}
		</footer>
	);
};

Footer.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Footer;
