import React from 'react';
import DefaultAside from 'pages/_layout/_asides/DefaultAside';
import { authPagesMenu } from '../menu';

const asides = [
	{ path: authPagesMenu.login.path, element: null },
	{ path: authPagesMenu.signUp.path, element: null },
	{ path: '*', element: <DefaultAside /> },
];

export default asides;
