import React from 'react';
import DefaultHeader from 'pages/_layout/_headers/DefaultHeader';
import { authPagesMenu } from '../menu';

const headers = [
	{ path: authPagesMenu.login.path, element: null },
	{ path: authPagesMenu.signUp.path, element: null },
	{ path: authPagesMenu.page404.path, element: null },
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
