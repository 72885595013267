import React, { lazy } from 'react';
import { dashboardPagesMenu, authPagesMenu, topPagesMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const SCREENER_LIST = {
	BOXED: lazy(() => import('../pages/presentation/screener/ScreenerBrowseView')),
};

const SCREENERS_INDEX = {
	BOXED: lazy(() => import('../pages/presentation/predefined-screeners/ScreenersIndexPage')),
};

const SCREENER_NEW_HIGHS_LOWS = {
	BOXED: lazy(() => import('../pages/presentation/predefined-screeners/NewHighsLowsPage')),
};

const SCREENER_TOP_LOSERS_GAINERS = {
	BOXED: lazy(() => import('../pages/presentation/predefined-screeners/TopGainersLosersPage')),
};

const SECTORS_LIST = {
	BOXED: lazy(() => import('../pages/presentation/sectors/SectorsListPage')),
};

const INDEXES_LIST = {
	BOXED: lazy(() => import('../pages/presentation/indexes/IndexesListPage')),
};

const INDEX_COMPONENTS_LIST = {
	BOXED: lazy(() => import('../pages/presentation/indexes/IndexComponentsPage')),
};

const WATCH_LIST = {
	BOXED: lazy(() => import('../pages/presentation/watchlist/WatchListPage')),
};

const INSTRUMENT = {
	BOXED: lazy(() => import('../pages/presentation/instruments/InstrumentPage')),
};

const INSTRUMENT_ALL_CHARTS = {
	BOXED: lazy(() => import('../pages/presentation/instruments/InstrumentCandlestickTripleChart')),
};

const SECTOR_STOCKS_LIST = {
	BOXED: lazy(() => import('../pages/presentation/sectors/SectorStocksPage')),
};

const MARKET_DASHBOARD = {
	BOXED: lazy(() => import('../pages/presentation/market/MarketPage')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: ``,
		element: <MARKET_DASHBOARD.BOXED />,
	},
	{
		path: `markets`,
		element: <MARKET_DASHBOARD.BOXED />,
	},
	{
		path: topPagesMenu.screener.path,
		element: <SCREENER_LIST.BOXED />,
	},
	{
		path: topPagesMenu.screeners.path,
		element: <SCREENERS_INDEX.BOXED />,
	},
	{
		path: topPagesMenu.markets.subMenu.indexes.path,
		element: <INDEXES_LIST.BOXED />,
	},
	{
		path: `index/:symbol/components`,
		element: <INDEX_COMPONENTS_LIST.BOXED />,
	},
	{
		path: topPagesMenu.markets.subMenu.sectors.path,
		element: <SECTORS_LIST.BOXED />,
	},
	{
		path: `stocks/:symbol`,
		element: <INSTRUMENT.BOXED />,
	},
	{
		path: `stocks/:symbol/all-charts`,
		element: <INSTRUMENT_ALL_CHARTS.BOXED />,
	},
	{
		path: `sector/:name/components`,
		element: <SECTOR_STOCKS_LIST.BOXED />,
	},
	{
		path: 'screeners/top-gainers-losers',
		element: <SCREENER_TOP_LOSERS_GAINERS.BOXED />,
	},
	{
		path: 'screeners/new-highs-lows',
		element: <SCREENER_NEW_HIGHS_LOWS.BOXED />,
	},

	{
		path: authPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: authPagesMenu.login.path,
		element: <Login />,
	},
	{
		path: authPagesMenu.signUp.path,
		element: <Login isSignUp />,
	},
];
const contents = [...presentation];

export default contents;
